<template>
  <esmp-modal
    v-model="visible"
    class="feast-modal"
    width="800"
    :loading="loading"
    @on-ok="save"
  >
    <template #header>
      {{ modalTitle }}
    </template>
    <validation-observer
      class="feast-form"
      ref="form"
      tag="div"
    >
      <validation-provider
        rules="required|min:5|max:255"
        :name="`«Название»`"
        v-slot="v"
        tag="div"
        class="form-item"
      >
        <esmp-input
          v-model="dto.title"
          label="Название"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
      <esmp-upload
        class="form-item"
        action="/api/files"
        type="drag"
        auto-upload
        :on-success="onUploadImage"
        :show-upload-list="false"
      >
        Добавить изображения
      </esmp-upload>
      <div class="feast-files">
        <div
          v-for="img in previewImages"
          :key="img.id"
          class="feast-files__item"
        >
          <img :src="img.url" class="feast-files__image">
          <esmp-button view="function" @click="removeImg(img.fileId)">
            <esmp-icon name="trash" />
          </esmp-button>
        </div>
      </div>
      <esmp-switch
        v-model="dto.isCustomCongratulationMessageNeed"
        class="form-item"
        shape="circular"
        label="Требуется специальное поздравление?"
      />
      <validation-provider
        v-if="dto.isCustomCongratulationMessageNeed"
        rules="required"
        :name="`«Специальное поздравление»`"
        v-slot="v"
        tag="div"
        class="form-item"
      >
        <esmp-input
          v-model="dto.defaultCongratulationMessage"
          :options="{ type: 'textarea' }"
          label="Специальное поздравление*"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
    </validation-observer>
  </esmp-modal>
</template>

<script>
export default {
  name: 'FeastsForm',
  model: {
    prop: 'show',
    event: 'input',
  },
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    isCustomCongratulationMessageNeed: {
      type: Boolean,
    },
    defaultCongratulationMessage: {
      type: String,
      default: '',
    },
    postcards: {
      type: Array,
      default: () => [],
    },
    isArchive: {
      type: Boolean,
    },
  },
  data() {
    return {
      previewImages: this.postcards,
      loading: false,
      dto: {
        title: this.title,
        isCustomCongratulationMessageNeed: this.isCustomCongratulationMessageNeed,
        defaultCongratulationMessage: this.defaultCongratulationMessage,
        postcards: this.postcards,
      },
    };
  },
  computed: {
    modalTitle() {
      return this.id ? 'Редактировать праздник' : 'Создать праздник';
    },
    visible: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    onUploadImage(res) {
      this.dto.postcards.push({
        fileId: res.id,
        url: res.url,
        sortOrder: res.id,
      });
      this.previewImages = this.dto.postcards;
    },
    removeImg(id) {
      this.$API.fileStorage.remove(id).then(() => {
        this.dto.postcards = this.dto.postcards.filter((el) => el.id !== id);
        this.previewImages = this.dto.postcards;
      });
    },
    save() {
      this.loading = true;
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.loading = false;
          this.$emit('on-save', this.dto);
        }
      });
    },
  },
  created() {
    if (this.id) this.dto.id = this.id;
    if (this.isArchive) this.dto.isArchive = this.isArchive;
  },
  watch: {
    id() {
      this.previewImages = this.$options.propsData.postcards;
      this.dto = this.$options.propsData;
    },
  },
};
</script>

<style lang="scss">
.feast-files {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 5px 0 0;
  }

  &__image {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }
}
</style>
